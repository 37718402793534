.App-headerLayout {
  background-color: #fef1e1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}
.TopHeaderLayout {
   margin-left: 0px;
  width: 100%;
  margin-top: 40px;
  z-index: 2;
}
.navBar {
  display: flex;
  align-self: flex-start;
  gap:14px;
  max-width:950px;
  margin: 0 auto;
  justify-content: space-around;
}
img.logoImage {
  max-width: 240px;
  transition: all 0.5s ease;
}
.Sport {
  color: #674917;
  text-decoration: none;
  margin: auto 0 !important;
  font-size: .8em;
  font-weight: bolder;
  transition: all 0.5s ease;
  cursor: pointer;
}
.Sport:hover {
  color: #9a6029 !important;
  text-decoration: none !important;
  scale: 1.1 !important;
}

.layoutContainer {
  width: 100%;
  background-image: url('imgs/cookies.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
  background-position: center center;
  height: 53vh;
  margin-top:50px;
}
img.backgroundImage {
  width: 100%;
}
.layoutTextContainer {
  width:100%;
  background-color: #5d3917;
  
}
.layoutTextContainer p {
  font-size: .7em;
  font-weight: bolder;
  max-width: 630px;
  margin:35px auto;
}
.LayoutFindTreat {
z-index:2;
}
.LayoutFindTreat h2 {
  font-size: 2em;
  color: #5d3917;
  font-family: monospace;
}
.layoutDarkContainer {
  background-color:  #5d3917;
  height:fit-content;
  width:100%;
  border-bottom: 8px solid #fef1e1;
}
.layoutImgContainer {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 20px;
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 20px auto;
}
.layoutContainer svg {
  width:100% !important;
  height:auto !important;
  z-index: 1;
  margin-top: -140px;
}
.layoutContainer .bottomSvg {
  width:100% !important;
  height:auto !important;
  z-index: 1;
  margin-top: 183px;
}
.layoutDarkContainer svg {
  width:100% !important;
  height:auto !important;
  z-index: 1;
  margin-top: -110px;
}
.layoutImg {
  height: 100%;
  width: 100%;
  max-width: 250px;
  max-height: 90%;
  margin: 0 10px;
  border: 6px solid white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.7s ease;
}
.layoutImg:hover {
  scale: 1.05;
}
img.cookiesImage {
  width: fit-content;
  height: fit-content;
  height: 100%;
  width: 100%;
  filter: brightness(0.6);
  border-radius: 8px;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-overlay p {
  font-weight: bolder;
  margin: 0;
}
.sepLayout {
background-color: #d2b513;
width: 100%;
height: 15px;
}
.layoutFormContainer {
  width:100%;
  color: #5d3917;
  margin-bottom: 30px;
  z-index:1;
}
.layoutFormContainer h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size:2em;
}
.layoutFormContainer h4 {
  margin-top: 00px;
  margin-bottom: 5px;
}
.layoutFormContainer p {
  margin: 0px;
}
.layoutFormForm {
  display: flex;
  border: 2px solid #d2b513;
  max-width: 600px;
  margin: 30px auto;
}
.layoutFormInput {
  flex: 1;
  border-right: 2px solid #d2b513;
}

.layoutFormInput .MuiInputLabel-formControl, .layoutFormDate .MuiInputLabel-formControl{
  top: -15px !important;
  left:20px !important;
}
label + .MuiInput-formControl {
   margin-top: 0px !important; 
}
.layoutFormForm input {
  margin-left:20px !important;
}
small {
  display: block;
  font-size: .6em;
  margin-top: 4px;
}

.layoutFooter {
  background-color: #5d3917 !important;
  width:100% !important;
}
.layoutFooter .footerCss {
  width:100% !important;
  height:auto !important;
  z-index: 1;
  margin-top: -110px;
}
.footerContainer {
  display: flex;
    padding: 16px;
    align-items: center;
    flex-direction: column;
    background-color: #5d3917;
    color: white;
    padding-bottom:0px !important;
    padding-top:0px !important;
}

.footerContainer a {
  color:#fef1e1 !important;
  font-size: .7em;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  }
  .makeStyles-linksRow-2 {
    margin-bottom: 0px !important;
}
@media (max-width: 1200px) {
  .navBar {
    gap: 35px;
    justify-content: center;
  }
  .Sport {
    font-size: .7em;
    
  }
  .TopHeaderLayout {
    margin-left: 40px;
    margin-right: 40px;
  }
  img.logoImage {
  max-width: 160px;
}
.layoutTextContainer p {
  padding-left:20px;
  padding-right:20px;
}
}
@media (max-width: 600px) {
  img.logoImage {
    max-width: 100px;
  }
}