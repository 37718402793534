.App {
  text-align: center;
}
* {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
body {
  margin:0;
}
.TopHeader {
  align-self: flex-start;
  margin-left: 20px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.playerHelpInfo {
  display: flex;
  /* gap: 35px; */
  font-size: .8em;
  flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;
}
.playerHelpInfo p {
  margin: 5px 10px;
}
.paddingBox {
  height:40px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header2 {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}
.chartDiv {
  background: #141413;
    padding: 20px;
    border-radius: 20px;
}
.App-link {
  color: #61dafb;
}

.selectedPageTitle {
  margin-top: 10px;
    text-align: left;
    font-size: 1em;
}
h1 {
  margin-bottom:5px;
}
.SportHome {
  color:white;
  text-decoration:none;
  cursor:pointer;
}

h2 {
  margin-top:20px;
  margin-bottom:20px;
}
.NBATITLE {
  margin-bottom:0 !important;
}
.scoreBoard {
  margin-bottom:10px;
}
h3 {
  margin-top:10px;
}
.selectionContainer {
  display: flex;
  gap: 10px;
}

.imageLabels {
  margin: 0 10px;
    align-content: center;
    align-items: center;
    border-radius: clamp(5px,1vw,10px);
    display: flex;
    -webkit-filter: brightness(60%);
    filter: brightness(60%);
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    transition: .1s ease;
    -webkit-user-select: none;
    user-select: none;
    min-width: 200px;
    max-height: 200px;
    background-color: white;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

}

.imageLabels:hover {
  cursor: pointer;
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
}
.imageLabels a {
  color:black;
  text-decoration:none;
  font-size:2em;
  filter: brightness(100%);
  display: flex;
}
.menuItems {
  text-decoration:none;
}
.activeMenuItem {
  color: white;
  font-weight: bold;
  background-color: #484e58;
  display: block;
}
.formImg {
  max-width:200px;
  max-height:100px;
}

.formButton {
  display: block;
    margin: 0 auto;
}

.guessList {
  color:white;
  margin:10px 0;
    font-size: .8em;
}

.hideAnswer {
  display:none;
}

 .hideButton {
  display:none;

}
.showAnswer, .showButton {
  display: inline-block;
  margin-top:10px;
  margin-bottom:10px;
}

.showButton {

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    /* margin: 0; */
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #207c40;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);

}
.otherButtons {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  /* margin: 0; */
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #207c40;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.blueButton {
  background-color: #2392e2;
}
.redButton {
  background-color: #780323 !important;
}
.greyButton {
  background-color: #818181 !important;
  margin-left: 10px !important;
}
.showButton:hover {
  text-decoration: none;
  background-color: #007b2b;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.showButton:disabled, .otherButtons:disabled {
  background-color: #777777 !important;
  cursor: not-allowed !important;
}

input[type="text"] {
  top: -10px;
}
.acoutcompleteHolder {
  background:white;
  padding: 10px;
  border-radius: 0.25rem;
}

.MuiPaper-root {
/* max-width:1400px; */
/* margin-top:20px; */
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  width: 98% !important;

}
.MuiTableCell-body {
  
}

tr {
  height: 10px;
}

tr td {
   height: auto !important;
}
.css-quj9j6-MuiTable-root {
    width:97% !important;
  margin: 10px auto;
}
.css-1ygcj2i-MuiTableCell-root, .css-1azl6jz-MuiTableCell-root, .css-1ex1afd-MuiTableCell-root, .css-177gid-MuiTableCell-root {
  padding:0 !important;
  height:17px;
}
.MuiTable-root {
  min-width:100px !important;
}
.css-1q1u3t4-MuiTableRow-root {
  height:17px;
}

.posterImg5 {
  filter: blur(16px);
  margin-top:8px;
  -webkit-filter: blur(16px);
  max-width: 400px;
}
.posterImg4 {
  filter: blur(14px);
  margin-top:8px;
  -webkit-filter: blur(14px);
  max-width: 400px;
}
.posterImg3 {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  max-width: 400px;
  margin-top:8px;
}
.posterImg2 {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  max-width: 400px;
  margin-top:8px;
}
.posterImg1 {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  max-width: 400px;
  margin-top:8px;
}
.posterImg0 {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  max-width: 400px;
  margin-top:8px;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  margin-bottom:20px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blurredText {
  text-shadow: 0 0 15px rgb(0, 0, 0);
    color: transparent;
}

.blurredTextNBA {
  text-shadow: 0 0 15px rgb(0, 0, 0);
  color: transparent;
  background:white;
}

.css-1ovif3x-MuiSkeleton-root {
  background-color: rgb(255 255 255 / 46%);
}
span.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-1ovif3x-MuiSkeleton-root, span.MuiSkeleton-root.MuiSkeleton-circular.MuiSkeleton-pulse.css-fomgco-MuiSkeleton-root, span.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-pulse.css-58ftfq-MuiSkeleton-root, span.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-pulse.css-1ukovu3-MuiSkeleton-root {

  background-color: rgb(255 255 255 / 46%);
}

.css-q34dxg{padding:3px !important;}.css-1bigob2{padding:3px !important;}.css-1sgp6db{padding:3px !important;}.css-1i36psw{padding:3px !important;}.css-13xy2my{width:95% !important;padding: 0 10px !important;}


.releaseP, .genreP {
  margin:5px 0;
  font-size:.7em;
}

.overview {
  font-size:.7em;
  margin: 5px 0;
  text-align: left;
}
.descText {
  font-size:.6em;
  margin: 5px 0;
  text-align: left;
}
.actorTitle {
  margin-bottom:0px;
  margin-top:0px;
}

.ProductionCompanyTitle {
  margin:5px 0;
  font-size: .7em;
}

.actorDiv {
  display:flex;
  flex-wrap:wrap;
  gap:20px;
  justify-content: center;
}

.actorName {
  margin:0px;
  margin-bottom:5px;
  font-size: .7em;
}

.JobP {
  margin:0px;
  margin-bottom:5px;
  font-size: .6em;
  font-style: italic; 
}
.actorImgContainer {
  width: 70px; /* Set the desired width */
  height: 70px; /* Set the desired height based on the aspect ratio */
  border-radius: 50%; /* Create the circular shape */
  overflow: hidden; /* Clip the content inside the circle */
  margin: 0 auto;
  /* Center the image horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.actorImgContainer img {
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain the image's aspect ratio */
  object-fit: cover; /* Crop the image to fill the circular shape */
  position: relative;
    top: 10px;
}

.circular-image {
  border-radius: 50%;
  width: 75px; /* Adjust the size as needed */
  font-size: .6em;

}
.circular-image-prod {
 max-height:100px;
  max-width: 100px; /* Adjust the size as needed */
  font-size: .6em;
}

.blurredTextNBA::selection, .blurredText::selection, .blurredImg::selection {
  text-shadow: 0 0 15px white;
  color: transparent;
}
.movieActor {
  display: flex;
  color: #020202;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.movieInfo {
  display: flex;
  gap:20px;
}
.movieDetails {
  text-align: left;
  font-size: .85em;

}
h3.actorTitle {
  font-size: .9em;
}
.blurredImg {
  filter: blur(5px);

  -webkit-filter: blur(5px);
}

.showText {
  display:block;
}
.noShowImg, .noShow {
  display:none;
}
.showImg {
  display:block;
  border-radius: 50%;
  background: #ffffff;
  width: 160px;
  height: 160px;
  object-fit: none;
  object-position: top;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 0 2px #ababab;
  transition: all 0.3s ease-in-out;

}


@media (max-width: 700px) {
  .mobileHide {
    display:none !important;
  }
  .MuiTableCell-head, .MuiTableCell-body {
    font-size: .6em !important;
  }
}